import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Button, Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'next-i18next';
import { CustomButton } from '../CustomButton';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  hideDescription?: boolean | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: theme.spacing(2),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
    flex: 1,
  },
})) as typeof CardContent;

const CardContentInfo = styled('div')(({ theme }) => ({
  flex: 1,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  color: '#000',
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  fontSize: theme.spacing(2),
}));

const ButtonStyled = styled(CustomButton)(({ theme }) => ({
  display: 'inline-block',
  margin: 0,
  marginTop: theme.spacing(-3.5),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CollectionContentC = (props: any) => {
  const { description, title, limit, className, hideDescription, hideTitle, ...other } = props;
  const { t } = useTranslation('card_content_c');
  const body2Ref = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      {title && description && (
        <CardContentStyled className={className}>
          <CardContentInfo>
            {!hideTitle && <CardTitle variant="caption" component="span" noWrap {...other}>
              {getText(title || '', limit || 35)}
            </CardTitle>}
            {!hideDescription && (
              <Description ref={body2Ref} variant="body2" component="p" color="textPrimary" {...other}>
                {description && getText(description, limit || 80)}
              </Description>
            )}
          </CardContentInfo>
          <ButtonStyled
            ref={buttonRef}
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            size="small"
          >
            {t('play_now', 'Play now')}
          </ButtonStyled>
        </CardContentStyled>
      )}
    </>
  );
};
